<template>
  <v-app>
    <v-navigation-drawer v-if="$vuetify.breakpoint.smAndUp" :mini-variant="true" app color="primary" permanent>
      <v-list color="white">
        <v-list-item>
          <v-list-item-action>
            <v-icon color="black" @click.stop="$router.push({name: 'Sensor'})">mdi-arrow-left</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main style="height: 100%">
      <div style="position: relative; height: 100%; width: 100%;">
        <router-view></router-view>
        <notification-list></notification-list>
      </div>
    </v-main>
    <v-footer v-if="$vuetify.breakpoint.smAndUp" app class="primary white--text justify-center"
              style="font-weight: 300; font-size: 14px;">
      <span style="letter-spacing: 1px;">2021 -- DIW-POMS</span>
    </v-footer>
  </v-app>
</template>

<script>
import NotificationList from '../components/notificationList'

export default {
  name: 'companyView',
  components: {
    NotificationList
  },
  data () {
    return {
      items: []
    }
  }
}
</script>

<style scoped>

::v-deep .v-toolbar__content, .v-toolbar__extension {
  padding: 0;
}

::v-deep .v-list-item-group .v-list-item--active {
  background: rgb(158, 137, 77);
  background: -moz-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  background: linear-gradient(90deg, rgba(158, 137, 77, 1) 0%, rgba(183, 166, 118, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9e894d", endColorstr="#b7a676", GradientType=1);
}
</style>
